import React from "react"

function PreviousInterestRateDetails({ previousInterestRates }){
return (
  <section className="content very-light-blue-background">
   <div className="content-block clearfix">
      <div className="eighty-spacer"></div>

      <div className="centered-content-container">
         <div className="centered-intro-title-holder">
            <h1>Previous Interest Rates</h1>
         </div>
      </div>

      <div className="twenty-spacer"></div>

      <div className="interest-rate-table-container">
         <div className="interest-rate-headings savings-txt-colour">
            <div className="interest-rate-row-half">
               Account Type
            </div>
            <div className="interest-rate-row-small">
               Gross Interest
            </div>
            <div className="interest-rate-row-small">
               Gross AER
            </div>
            <div className="interest-rate-row-small">
               Net Interest
            </div>
         </div>
         {previousInterestRates.length
           ? previousInterestRates.map((prod,index) => (

               <div className="interest-rate-row" key={index}>
                  <div className="interest-rate-row-half">
                     {prod.accountType}
                    </div>

                  <div className="interest-rate-row-small">
                     <div className="mobile-interest-headings">
                        Gross Interest
                     </div>
                     {prod.grossInterest}
                  </div>

                  <div className="interest-rate-row-small">
                     <div className="mobile-interest-headings">
                        Gross AER
                     </div>
                     {prod.grossAer}
                  </div>

                  <div className="interest-rate-row-small">
                     <div className="mobile-interest-headings">
                        Net Interest
                     </div>
                     {prod.netInterest}
                  </div>
               </div>
             ))
           : null}

                  </div>
   </div>
   <div className="eighty-spacer"></div>
</section>
 )
  }


export default PreviousInterestRateDetails
